import React from "react";
import PropTypes from "prop-types";

import { Wrapper, BundestagLogo, DigitalJetzLogo } from "./StyledFooterLogos";

import Grid from "@mui/material/Grid";

const FooterLogos = ({ digitalJetzLogoSrc, bundestagLogoSrc }) => {
  const text = I18n.t("home.supported_by");
  const isMobile = window.innerWidth < 450;

  return (
    <>
      <Wrapper container columnSpacing={2}>
        {isMobile ? (
          <>
            <Grid item xs={7}>
              <DigitalJetzLogo src={digitalJetzLogoSrc} />
              <BundestagLogo src={bundestagLogoSrc} />
            </Grid>
            <Grid item xs={5}>
              {text}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4}>
              <BundestagLogo src={bundestagLogoSrc} />
            </Grid>
            <Grid item xs={4}>
              {text}
            </Grid>
            <Grid item xs={4}>
              <DigitalJetzLogo src={digitalJetzLogoSrc} />
            </Grid>
          </>
        )}
      </Wrapper>
    </>
  );
};

FooterLogos.propTypes = {
  digitalJetzLogoSrc: PropTypes.string,
  bundestagLogoSrc: PropTypes.string,
};

export default FooterLogos;
