import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";

export const Wrapper = styled(Grid)({
  backgroundColor: "#FFFFFF",
  alignItems: "center",
  padding: "20px",
  justifyContent: "space-around",
});

export const BundestagLogo = styled("img")({
  maxHeight: "250px",
});

export const DigitalJetzLogo = styled("img")({
  maxHeight: "180px",
});
