import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { EventSurvey } from "@shared/eventFeatures";

import { selectActiveEvent } from "@store/slices/eventsSlice";

import useEvent from "@hooks/useEvent";

const EventSurveyContainer = ({ eventId, jwt, overwriteState }) => {
  const event = useSelector(selectActiveEvent);

  useEvent({
    eventId: eventId,
    jwt: jwt,
    overwriteState: overwriteState,
  });

  return (
    <>
      {event && !!event.activeSurvey && (
        <EventSurvey id="survey" forId={event.activeSurvey} />
      )}
    </>
  );
};

EventSurveyContainer.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  overwriteState: PropTypes.string,
};

export default EventSurveyContainer;
