import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

import RootComponent from "@shared/RootComponentv4";
import EventSurveyContainer from "./EventSurveyContainer";

const Survey = ({ eventId, jwt, overwriteState, projectorView }) => {
  if (projectorView) return <></>;

  return (
    <RootComponent>
      <Grid container justifyContent="center" alignItems="center">
        <EventSurveyContainer
          eventId={eventId}
          jwt={jwt}
          overwriteState={overwriteState}
        />
      </Grid>
    </RootComponent>
  );
};

Survey.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  overwriteState: PropTypes.string,
  projectorView: PropTypes.string,
};

export default Survey;
