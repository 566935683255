// Delete this file after migrating to mui version 5

import React from "react";
import PropTypes from "prop-types";

import store from "@store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import theme from "@styles/theme";

const RootComponentv4 = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Provider store={store}>{children}</Provider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

RootComponentv4.propTypes = {
  children: PropTypes.node,
};

export default RootComponentv4;
