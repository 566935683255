import React from "react";
import PropTypes from "prop-types";

import RootComponent from "@shared/RootComponent";
import EventContainer from "./EventContainer";
import { EventDescription } from "@shared/eventFeatures";

const EventDescriptionLegacy = ({ eventId }) => {
  return (
    <RootComponent>
      <EventContainer eventId={eventId}>
        <EventDescription editable={true} />
      </EventContainer>
    </RootComponent>
  );
};

EventDescriptionLegacy.propTypes = {
  eventId: PropTypes.any,
};

export default EventDescriptionLegacy;
