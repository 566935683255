import React from "react";
import Enzyme, { shallow } from "enzyme";

import Adapter from "@wojtekmaj/enzyme-adapter-react-17";
import QaTool from "./QaTool";

Enzyme.configure({ adapter: new Adapter() });

describe("QaTool Component", () => {
  let wrapper;

  it("Renders the DataContainer", () => {
    wrapper = shallow(<QaTool eventId="VnkR6GQkDa" />);
    expect(wrapper.find("DataContainer")).toHaveLength(1);
  });

  describe("When in projectorView", () => {
    it("Does not render the DataContainer", () => {
      wrapper = shallow(<QaTool eventId="VnkR6GQkDa" projectorView={true} />);
      expect(wrapper.find("DataContainer")).toHaveLength(0);
    });
  });
});
