import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import EventQuestions from "@shared/eventFeatures/eventQuestions";
import RootComponent from "@shared/RootComponentv4";
import useEvent from "@hooks/useEvent";

import { selectActiveEvent } from "@store/slices/eventsSlice";

const DataContainer = ({ eventId, jwt, overwriteState }) => {
  const event = useSelector(selectActiveEvent);
  useEvent({
    eventId: eventId,
    jwt: jwt,
    overwriteState: overwriteState,
  });

  return <>{event && <EventQuestions forEventId={event.id} />}</>;
};

DataContainer.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  overwriteState: PropTypes.string,
};

const QaTool = ({ eventId, jwt, overwriteState, projectorView }) => {
  if (projectorView) return <></>;

  return (
    <RootComponent>
      <Grid
        container
        id="questions"
        justifyContent="center"
        alignItems="center"
      >
        <DataContainer
          eventId={eventId}
          jwt={jwt}
          overwriteState={overwriteState}
        />
      </Grid>
    </RootComponent>
  );
};

QaTool.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  overwriteState: PropTypes.string,
  projectorView: PropTypes.bool,
};

export { DataContainer };

export default QaTool;
