import PropTypes from "prop-types";
import { selectActiveEvent } from "@store/slices/eventsSlice";
import { useSelector } from "react-redux";
import useEvent from "@hooks/useEvent";

const EventContainer = ({ eventId, children }) => {
  useEvent({ eventId: eventId });
  const event = useSelector(selectActiveEvent);

  return event ? children : null;
};

EventContainer.propTypes = {
  eventId: PropTypes.string,
  children: PropTypes.node,
};

export default EventContainer;
